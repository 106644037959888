import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

const ThankYouPage = () => (
  <Layout>
    <h1>Thank You!</h1>
    <p>We will get in touch soon</p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default ThankYouPage
